import dayjs from 'dayjs'
import xor from 'lodash/xor'
import { Controller } from 'stimulus'
import { ApiClient, ApiError } from '../../../utils/ApiClient'

const SHOPS = [
  {
    key: 'jimbocho',
    name: '神保町店',
  },
  {
    key: 'kichijoji',
    name: '吉祥寺店',
  },
  {
    key: 'anotherShop',
    name: 'その他',
  },
]
const SHOP_KEYS = SHOPS.map(shop => shop.key)
const SHOP_MAP = Object.fromEntries(SHOPS.map(shop => [shop.key, shop]))

export default class extends Controller {
  // Oc = Offcanvas
  static targets = [
    'selectShopDropdownMenuButton',
    'anotherShopButton',
    'anotherShopNameInput',
    'codesInput',
    'checkboxForAll',
    'bgcardCodeOcLabel',
    'bgcardCodeOcProductNames',
    'bgcardCodeOcUsedAt',
    'bgcardCodeOcOrderKey',
    'bulkUseBtn',
    'bulkUnuseBtn',
    'tsvInput',
    'tsvSubmit',
  ]
  static values = {
    bgcardCodes: Array,
    selectedCodes: Array,
  }

  get shopKey() {
    const shopKey = localStorage.getItem('shopKey')
    return SHOP_KEYS.includes(shopKey) ? shopKey : 'kichijoji'
  }

  set shopKey(value) {
    localStorage.setItem('shopKey', value)
  }

  get anotherShopName() {
    return localStorage.getItem('anotherShopName') ?? ''
  }

  set anotherShopName(value) {
    localStorage.setItem('anotherShopName', value)
  }

  get shopName() {
    return this.shopKey === 'anotherShop'
      ? this.anotherShopName
      : SHOP_MAP[this.shopKey].name
  }

  connect() {
    this.selectShopDropdownMenuButtonTarget.textContent = `${this.shopName}で利用中`
    this.anotherShopNameInputTarget.value = this.anotherShopName

    // 操作性が悪くならないよう、その他の店舗はスペースキーで選択されないようにしておく
    this.anotherShopButtonTarget.addEventListener('keydown', function (event) {
      if (event.code === 'Space') event.preventDefault()
    })
  }

  onShopSelected(event) {
    const shopKey = event.params.shopKey
    this.shopKey = shopKey
    if (shopKey === 'anotherShop') {
      const anotherShopName = this.anotherShopNameInputTarget.value
      this.selectShopDropdownMenuButtonTarget.textContent = `${anotherShopName}で利用中`
      this.anotherShopName = anotherShopName
    } else {
      this.selectShopDropdownMenuButtonTarget.textContent = `${SHOP_MAP[shopKey].name}で利用中`
    }
  }

  async onCodeClicked(event) {
    const targetCode = event.detail.code
    const bgcardCode = this.bgcardCodesValue.find(bc => bc.code === targetCode)
    const used = !!bgcardCode.used_at

    this.bgcardCodeOcLabelTarget.innerHTML = `
      <span class='badge rounded-pill fw-normal me-1 ${
        used ? 'bg-secondary' : 'bg-warning'
      }'>
        ${used ? '使用済み' : '未使用'}
      </span>
      ${bgcardCode.code.match(/.{1,4}/g).join('-')}`
    this.bgcardCodeOcUsedAtTarget.textContent = used
      ? dayjs(bgcardCode.used_at).format('YYYY-MM-DD HH:mm:ss')
      : '-'
    this.bgcardCodeOcOrderKeyTarget.textContent = used
      ? bgcardCode.order_key
      : '-'

    if (bgcardCode.edition === 3)
      return (this.bgcardCodeOcProductNamesTarget.innerHTML =
        '<li>第3弾対象のボードゲーム</li>')
    try {
      const res = await ApiClient.get('/api/web/product_items/names', {
        params: {
          root_name: bgcardCode.root_name,
        },
      })
      const productNames = res.data.item_names
      this.bgcardCodeOcProductNamesTarget.innerHTML = productNames
        .map(productName => `<li>${productName}</li>`)
        .join('')
    } catch (e) {
      alert('対象のボードゲームの取得に失敗しました。')
    }
  }

  clearCodesInput(event) {
    event.preventDefault()
    this.codesInputTarget.value = ''
  }

  onAllCheckedChanged() {
    this.selectedCodesValue = this.checkboxForAllTarget.checked
      ? this.bgcardCodesValue.map(bc => bc.code)
      : []
    this.dispatch('selectedCodesChanged', {
      detail: { codes: this.selectedCodesValue },
    })
  }

  updateSelectedCodes(event) {
    this.selectedCodesValue = xor(this.selectedCodesValue, [event.detail.code])
    this.dispatch('selectedCodesChanged', {
      detail: { codes: this.selectedCodesValue },
    })
  }

  onSelectedCodesChanged() {
    const isIncludedUsed = this.selectedCodesValue.find(
      sc => this.bgcardCodesValue.find(bc => bc.code === sc).used_at,
    )
    const isIncludedUnused = this.selectedCodesValue.find(
      sc => !this.bgcardCodesValue.find(bc => bc.code === sc).used_at,
    )
    this.bulkUseBtnTarget.disabled =
      this.selectedCodesValue.length === 0 || isIncludedUsed
    this.bulkUnuseBtnTarget.disabled =
      this.selectedCodesValue.length === 0 || isIncludedUnused
  }

  onTsvUploadBtnClicked() {
    this.tsvInputTarget.click()
  }

  onTsvUploaded() {
    this.tsvSubmitTarget.click()
  }

  async onBulkUseBtnClicked() {
    await this.bulkUse(this.selectedCodesValue)
  }

  async onBulkUnuseBtnClicked() {
    if (!confirm('選択したシリアルコードを未使用に戻しますか？')) return
    await this.bulkUnuse(this.selectedCodesValue)
  }

  async bulkUse(codes) {
    try {
      await ApiClient.post('/api/admin/bgcard_codes/used/batch', {
        codes: codes,
        shop_name: this.shopName,
      })
      location.reload()
    } catch (error) {
      if (error instanceof ApiError) {
        alert(error.message)
      } else {
        // @todo: Sentryに送る
        // console.log(error.message)
      }
    }
  }

  async bulkUnuse(codes) {
    try {
      await ApiClient.request({
        method: 'delete',
        url: '/api/admin/bgcard_codes/used/batch',
        data: { codes: codes },
      })
      location.reload()
    } catch (error) {
      if (error instanceof ApiError) {
        alert(error.message)
      } else {
        // @todo: Sentryに送る
        // console.log(error.message)
      }
    }
  }
}
