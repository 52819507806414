import { Controller } from 'stimulus'

/*
 * controllerのstateValueの値に応じて、要素の表示・非表示やclassの切り替えを行う機能を追加する
 *
 * 例えば、state1, state2というstateを持つ場合は、controllerのstaticなプロパティとして
 * static states = {
 *  state1: 'state1',
 *  state2: 'state2',
 * }
 * static values = {
 *  state: { type: String, default: 'state1' },
 * }
 * と定義する
 *
 * 機能1: 要素の表示・非表示
 * stateValueの値がstate1の場合は、data-{controller}-display-state="state1"という要素が表示され、
 * stateValueの値がstate2の場合は、data-{controller}-display-state="state2"という要素が表示される
 * data-{controller}-display-state="state1 state2"というように複数のstateを指定することも可能
 *
 * 機能2: 要素のclassの切り替え
 * stateValueの値がstate1の場合は、 data-{controller}-state1-classで指定されたclassが要素に追加され、
 * stateValueの値がstate2の場合は、 data-{controller}-state2-classで指定されたclassが要素に追加される
 * data-{controller}-classでclassを指定すると、state固有のclass設定が存在しない場合に要素に追加される
 *  */
export class ApplicationController extends Controller {
  initialize() {
    this._setStates()
    this._setStateChangedDisplayHandler()
    this._setStateChangedClassHandler()
  }

  // stateの一覧を設定する
  _setStates() {
    this.states =
      Object.getPrototypeOf(Object.getPrototypeOf(this)).constructor.states ??
      {}
  }

  // stateに応じて要素の表示・非表示を切り替える
  _setStateChangedDisplayHandler() {
    const attributeName = `data-${this.identifier}-display-state`
    const targets = this._getElementsByDataAttributeName(
      this.element,
      attributeName,
    )
    targets.forEach(target => {
      const states = this._getDataAttributeValues(target, attributeName)
      this._addHandlerWithRequestAnimationFrame(
        this,
        'stateValueChanged',
        value => {
          if (states.includes(value)) {
            target.classList.remove('d-none')
          } else {
            target.classList.add('d-none')
          }
        },
      )
    })
  }

  // stateValueに応じて要素のclassを切り替える
  _setStateChangedClassHandler() {
    const attributeNames = [
      `data-${this.identifier}-class`,
      ...Object.values(this.states).map(
        state => `data-${this.identifier}-${state}-class`,
      ),
    ]
    const targets = this._getElementsByDataAttributeName(
      this.element,
      ...attributeNames,
    )

    targets.forEach(target => {
      this._addHandlerWithRequestAnimationFrame(
        this,
        'stateValueChanged',
        value => {
          // 該当stateのclass
          const stateClasses = this._getDataAttributeValues(
            target,
            `data-${this.identifier}-${value}-class`,
          )
          // 他のstateのclass
          const otherStateClasses = Object.values(this.states)
            .filter(s => s !== value)
            .flatMap(state =>
              this._getDataAttributeValues(
                target,
                `data-${this.identifier}-${state}-class`,
              ),
            )
          // デフォルトのclass
          const defaultClasses = this._getDataAttributeValues(
            target,
            `data-${this.identifier}-class`,
          )

          // 他のstateとデフォルトのclassを削除
          target.classList.remove(...otherStateClasses, ...defaultClasses)
          // 該当するstateのclassを追加、該当がなければデフォルトのclassを追加
          if (stateClasses.length) {
            target.classList.add(...stateClasses)
          } else {
            target.classList.add(...defaultClasses)
          }
        },
      )
    })
  }

  // ハイフン区切りの文字列からdata-attributeを取得する（スペース区切りで配列にする）
  _getDataAttributeValues(target, attributeName) {
    // 'data-'で始まる場合は除去する
    if (`${attributeName}`.startsWith('data-')) {
      attributeName = attributeName.slice(5)
    }
    return (
      target.dataset[
        `${attributeName
          .toLowerCase()
          .replaceAll(/-([a-z])/g, (s, m) => m.toUpperCase())}`
      ] ?? ''
    )
      .split(' ')
      .filter(v => v)
  }

  // 自身を含めて、指定したdata-attributeを持つ要素を取得する
  _getElementsByDataAttributeName(target, ...attributeNames) {
    const targets = [
      ...target.querySelectorAll(
        `${attributeNames.map(v => `[${v}]`).join(',')}`,
      ),
    ]
    // 自身が指定したdata-attributeを持つ場合は自身を追加する
    if (
      attributeNames.find(attributeName => {
        return this._getDataAttributeValues(target, attributeName).length
      })
    )
      targets.push(target)
    return targets
  }

  // requestAnimationFrameを使ってhandlerを追加する
  _addHandlerWithRequestAnimationFrame(that, target, handler) {
    const original = that[target]
    that[target] = function (...args) {
      original?.call(that, ...args)
      window.requestAnimationFrame(() => {
        handler.call(that, ...args)
      })
    }
  }
}
