let stripe
let clientSecret
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['refreshBtn', 'orderBtn', 'errorMessage']
  static classes = ['hidden']
  static values = {
    payment: String,
    name: String,
    email: String,
    phone: String,
  }

  initialize() {
    stripe = Stripe(gon.stripe_public_key)
    clientSecret = gon.stripe_client_secret
  }

  async connect() {
    this.hiddenErrorMessage()
  }

  // NOTE: submit時に同じformの別のdisableWithを書き換えとく
  refresh() {
    this.orderBtnTarget.dataset.disableWith = '実際に<br>注文する'
  }

  // 実際に注文するボタン
  async order(event) {
    let baseUrl = `${location.protocol}//${location.host}`
    let confirmUrl = `${baseUrl}/order/confirm`
    switch (this.paymentValue) {
      case 'クレジット決済':
        event.preventDefault()
        await this.confirmCard(confirmUrl)
        break
      case 'コンビニ支払い':
        event.preventDefault()
        await this.confirmKonbini(confirmUrl)
        break
      default:
        this.refreshBtnTarget.dataset.disableWith = '最新状態に<br>更新'
    }
  }

  // 支払い確定と3Dセキュア
  async confirmCard(confirmUrl) {
    this.setLoading(true)
    const result = await stripe.confirmCardPayment(clientSecret)
    if (result.error) {
      this.setLoading(false)
      this.addErrorMessage(result.error.message)
    } else {
      // 支払い確定に成功したら、処理ページへ
      location.assign(confirmUrl)
    }
  }

  // コンビニ決済
  async confirmKonbini(confirmUrl) {
    this.setLoading(true)

    const result = await stripe.confirmKonbiniPayment(
      clientSecret,
      {
        payment_method: {
          billing_details: {
            name: this.nameValue,
            email: this.emailValue,
          },
        },
        payment_method_options: {
          konbini: {
            confirmation_number: this.phoneValue.replaceAll('-', ''),
          },
        },
      },
      { handleActions: false },
    )
    if (result.error) {
      this.setLoading(false)
      this.addErrorMessage(result.error.message)
    } else {
      // 支払い確定に成功したら、処理ページへ
      location.assign(confirmUrl)
    }
  }

  // preventDefaultを使用すると、disable_withによるボタンの非活性化が機能しないのでJSで制御する
  setLoading(isLoading) {
    this.refreshBtnTarget.disabled = isLoading
    this.orderBtnTarget.disabled = isLoading
    if (isLoading) {
      this.orderBtnTarget.innerHTML =
        '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>注文中...'
    } else {
      this.orderBtnTarget.innerHTML = '実際に<br>注文する'
    }
  }

  hiddenErrorMessage() {
    if (this.errorMessageTarget.childElementCount < 2)
      this.errorMessageTarget.classList.add(this.hiddenClass)
  }

  // エラーメッセージを出力し、決定ボタンをdisable化
  addErrorMessage(messageText) {
    const elm = document.createElement('p')
    elm.className = 'mb-1'
    elm.innerHTML = messageText
    this.errorMessageTarget.appendChild(elm)
    this.errorMessageTarget.classList.remove(this.hiddenClass)
    this.scrollTop()
    this.orderBtnTarget.disabled = true
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
}
