import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['text', 'indicator']

  start() {
    this.element.disabled = true // 要素を無効化
    this.textTarget.classList.add('d-none')
    this.indicatorTarget.classList.remove('d-none')
  }

  end() {
    this.element.disabled = false // 要素を有効化
    this.textTarget.classList.remove('d-none')
    this.indicatorTarget.classList.add('d-none')
  }
}
