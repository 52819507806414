import { Modal } from 'bootstrap'

export class ModalManager {
  constructor() {
    this.modalElement = null
    this.modal = null
    this.callbacks = new Map()
  }

  createModal(options = {}) {
    const {
      title = '',
      body = '',
      buttons = [],
      size = '', // '', 'sm', 'lg', 'xl'
      centered = true,
      scrollable = false,
      fullscreen = false,
    } = options

    if (this.modalElement) {
      this.destroy()
    }

    this.modalElement = document.createElement('div')
    this.modalElement.className = 'modal fade'
    this.modalElement.setAttribute('tabindex', '-1')

    // モーダルのダイアログクラスを構築
    const dialogClasses = ['modal-dialog']
    if (size) dialogClasses.push(`modal-${size}`)
    if (centered) dialogClasses.push('modal-dialog-centered')
    if (scrollable) dialogClasses.push('modal-dialog-scrollable')
    if (fullscreen) dialogClasses.push('modal-fullscreen')

    const buttonElements = buttons
      .map((button, index) => {
        const {
          text = '',
          class: buttonClass = 'btn-secondary',
          dismiss = false,
          onClick,
        } = button

        const buttonElement = `
        <button type="button" 
                class="btn ${buttonClass}" 
                ${dismiss ? 'data-bs-dismiss="modal"' : ''}
                data-button-index="${index}">
          ${text}
        </button>
      `
        if (onClick) {
          this.callbacks.set(index, onClick)
        }
        return buttonElement
      })
      .join('')

    this.modalElement.innerHTML = `
      <div class="${dialogClasses.join(' ')}">
        <div class="modal-content">
          ${
            title
              ? `
            <div class="modal-header">
              <h5 class="modal-title fw-bold">${title}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          `
              : ''
          }
          <div class="modal-body text-start">${body}</div>
          ${
            buttons.length > 0
              ? `
            <div class="modal-footer">
              ${buttonElements}
            </div>
          `
              : ''
          }
        </div>
      </div>
    `

    // ボタンのクリックイベントを設定
    for (const button of this.modalElement.querySelectorAll(
      '[data-button-index]',
    )) {
      const index = Number.parseInt(button.dataset.buttonIndex)
      const callback = this.callbacks.get(index)
      if (callback) {
        button.addEventListener('click', e => {
          callback(e)
          if (!e.defaultPrevented) {
            this.modal.hide()
          }
        })
      }
    }

    document.body.appendChild(this.modalElement)
    this.modal = new Modal(this.modalElement)
    this.modal.show()

    // モーダルが閉じられたときのクリーンアップ
    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.destroy()
    })

    return this.modal
  }

  destroy() {
    if (this.modalElement) {
      this.callbacks.clear()
      this.modal.dispose()
      document.body.removeChild(this.modalElement)
      this.modalElement = null
      this.modal = null
    }
  }
}

export const showModal = options => {
  const manager = new ModalManager()
  manager.createModal(options)
  return manager
}
