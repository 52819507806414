import { Controller } from 'stimulus'
import { ApiClient } from '../../../utils/ApiClient'
import { showModal } from '../../../utils/modal'

export default class extends Controller {
  static targets = ['url', 'title', 'image', 'imageBase64', 'button']

  async fetch() {
    const url = this.urlTarget.value?.trim()
    try {
      if (!this.isValidUrl(url)) throw '有効なURLを入力してください。'
      const res = await ApiClient.get(
        `/api/web/echosys/ogp?url=${encodeURIComponent(url)}`,
      )
      const data = res.data
      if (!data) throw 'リンク先の情報を取得できませんでした。'
      this.updateContent(data)
    } catch (error) {
      showModal({
        title: '情報取得エラー',
        body:
          typeof error === 'string'
            ? error
            : 'リンク先の情報を取得できませんでした。',
        buttons: [
          { text: '閉じる', class: 'btn-secondary mx-auto', dismiss: true },
        ],
      })
    } finally {
      this.dispatch('end', { target: this.buttonTarget })
    }
  }

  isValidUrl(url) {
    try {
      new URL(url)
      return true
    } catch {
      return false
    }
  }

  updateContent(data) {
    if (data.title) {
      if (data.title.length > 32) {
        this.titleTarget.value = `${data.title.slice(0, 31)}…`
      } else {
        this.titleTarget.value = data.title
      }
      this.titleTarget.dispatchEvent(new Event('change'))
    }
    if (data.url !== this.urlTarget.value) {
      this.urlTarget.value = data.url
      this.urlTarget.dispatchEvent(new Event('change'))
    }
    this.imageTarget.innerHTML = ''
    if (data.image_base64) {
      const image = new Image()
      const extension = data.image.split('.').pop()
      const dataUrl = `data:image/${extension};base64,${data.image_base64}`
      image.src = dataUrl
      image.className = 'w-100 h-100 object-fit-contain'
      image.onload = () => {
        this.imageTarget.appendChild(image)
        this.imageBase64Target.value = dataUrl
      }
    } else {
      this.imageBase64Target.value = 'delete'
    }
  }
}
