import { diffChars } from 'diff'
import { ApplicationController } from '../../application_controller'

export const STATE = {
  ORIGINAL: 'original',
  DIFF: 'diff',
}

export class DiffController extends ApplicationController {
  connect() {
    this.select()
  }

  select(event) {
    this.stateValue = event?.params?.state ?? this.stateValue
    this.selectedValue = event?.params?.selected ?? this.selectedValue

    // 状態の表示更新
    if (this.hasStatusTarget) {
      this.statusTargets.forEach((t, i) => {
        t.classList.add('d-none')
        if (i === this.selectedValue) {
          t.classList.remove('d-none')
        }
      })
    }

    // 却下理由の表示更新
    if (this.hasExaminationsTarget) {
      this.examinationsTargets.forEach((t, i) => {
        t.classList.add('d-none')
        if (i === this.selectedValue) {
          t.classList.remove('d-none')
        }
      })
    }

    // セレクターの表示更新
    this.selectorTargets.forEach((t, i) => {
      t.querySelectorAll('.active').forEach($ => $.classList.add('d-none'))
      t.querySelectorAll('.inactive').forEach($ => $.classList.remove('d-none'))
      if (i === this.selectedValue) {
        if (this.stateValue === STATE.ORIGINAL) {
          t.querySelectorAll('.original.active').forEach($ =>
            $.classList.remove('d-none'),
          )
          t.querySelectorAll('.original.inactive').forEach($ =>
            $.classList.add('d-none'),
          )
        } else {
          t.querySelectorAll('.diff.active').forEach($ =>
            $.classList.remove('d-none'),
          )
          t.querySelectorAll('.diff.inactive').forEach($ =>
            $.classList.add('d-none'),
          )
        }
      }
    })
  }

  // バッジのHTMLに変換する
  createItemsBadgeHTML(items) {
    return items
      .map(
        item =>
          `<a href="${item.game_info_url}" target="_blank" class="badge me-2" style="text-decoration:none;">${item.item_name}</a>`,
      )
      .join('')
  }

  // 差分をHTMLに変換する
  createDiffHTML(from, to) {
    const diff = diffChars(from, to)
    let html = ''
    html = '<p>'
    for (const d of diff) {
      const lines = d.value.split(/\r\n|\n|\r/)
      // 追加の場合
      if (d.added) {
        html += lines
          .map((l, i) =>
            i !== lines.length - 1
              ? `<ins>${l}</ins></p><p>`
              : l
                ? `<ins>${l}</ins>`
                : '',
          )
          .join('')
        // 削除の場合
      } else if (d.removed) {
        html += lines
          .map((l, i) =>
            i !== lines.length - 1
              ? `<del>${l}</del></p><p>`
              : l
                ? `<del>${l}</del>`
                : '',
          )
          .join('')
        // 変更なしの場合
      } else {
        html += lines
          .map((l, i) => (i !== lines.length - 1 ? `${l}</p><p>` : l))
          .join('')
      }
    }
    html += '</p>'
    return html
  }

  // 改行を<p>タグに変換する
  replaceNewLine(text) {
    return text
      .split(/\r\n|\n|\r/)
      .map(l => `<p>${l}</p>`)
      .join('')
  }

  // 指摘された行をハイライトする
  highlightLine(htmlString, line) {
    if (!line) return htmlString
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    const paragraphs = doc.querySelectorAll('p')
    paragraphs[line - 1].classList.add('bg-warning-subtle')
    return doc.body.innerHTML
  }

  // HTMLエスケープ
  escapeHTML(string) {
    if (typeof string !== 'string') {
      return string
    }
    return string.replace(/[&'`"<>]/g, function (match) {
      return {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
      }[match]
    })
  }
}
