import { Controller } from 'stimulus'
import { ApiClient } from '../../utils/ApiClient'
import { formatErrorMessage } from '../../utils/format'

export default class extends Controller {
  static targets = ['inputBgcardCode', 'errorText', 'applyButton', 'closeBtn']

  static values = {
    sku: String,
    bgcardCodes: Array,
  }

  connect() {
    this.dispatch('applied', {
      detail: {
        sku: this.skuValue,
        bgcardCodes: this.bgcardCodesValue,
      },
    })
  }

  async apply() {
    this.applyButtonTarget.disabled = true
    const inputBgcardCodeText = this.inputBgcardCodeTarget.value
    const sku = this.skuValue

    const errorTextElement = this.errorTextTarget
    const bgcardCodes = this.bgcardCodesValue

    try {
      const res = await ApiClient.post('/api/web/bgcard_codes/verified', {
        bgcard_code: inputBgcardCodeText,
        sku: sku,
      })

      bgcardCodes.push(res.data.result)
      this.bgcardCodesValue = bgcardCodes

      errorTextElement.textContent = ''
      this.dispatch('applied', {
        detail: {
          sku: sku,
          bgcardCodes: bgcardCodes,
        },
      })
      this.inputBgcardCodeTarget.value = ''
      this.closeBtnTarget.click()
    } catch (error) {
      errorTextElement.textContent = formatErrorMessage(error)
    } finally {
      errorTextElement.classList.remove('d-none')
      this.applyButtonTarget.disabled = false
    }
  }
}
