import { Controller } from '@hotwired/stimulus'
import * as Sentry from '@sentry/browser'
import Compressor from 'compressorjs'

export default class extends Controller {
  static targets = ['file', 'compressed', 'preview']

  compress() {
    const file = this.fileTarget.files[0]
    if (!file) return

    // 画像の向きを確認するための一時的なImage要素を作成
    const img = new Image()
    img.onload = () => {
      const isPortrait = img.height > img.width

      new Compressor(file, {
        maxWidth: isPortrait ? 248 : undefined, // 短辺が248px以下になるようにリサイズ
        maxHeight: isPortrait ? undefined : 248,
        resize: 'contain', // アスペクト比を維持
        quality: 0.8,
        mimeType: 'auto',
        success: result => {
          const reader = new FileReader()
          reader.onload = e => {
            this.compressedTarget.value = e.target.result
            this.previewTarget.src = e.target.result
            this.previewTarget.classList.remove('d-none')
          }
          reader.readAsDataURL(result)
        },
        error(err) {
          Sentry.captureException(err)
        },
      })
    }

    // 一時的なImage要素にファイルを読み込む
    img.src = URL.createObjectURL(file)
  }
}
