// 注意：utf-8m (BOM付UTF-8？)で保存しないと、iPadで出力が文字化けしてしまう。

import $ from 'jquery'

document.addEventListener('turbolinks:load', function () {
  // $.tag
  $.tag = function (name) {
    return $(`<${name} />`)
  }

  // tag
  $.fn.tag = function (name) {
    return this.pushStack($.tag(name))
  }

  // gat
  $.fn.tagend = function () {
    return this.end().append(this)
  }

  // ページ指定ありで表示した場合には、そのページバーが画面上部になるように画面位置を飛ばす
  const matched = window.location.search.match(/\bp=(\d+)\b/)
  if (matched != null) {
    $(window).scrollTop($(`#bar_page${matched[1]}`).offset().top - 70)
  }
})
