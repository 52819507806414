import { Controller } from 'stimulus'
import { ApiClient } from '../utils/ApiClient'

export default class extends Controller {
  static targets = ['content', 'title', 'unreadBadge', 'button']
  static values = {
    notificationTargetId: Number,
    unread: Boolean,
  }

  connect() {
    // 未読状態を反映
    this.updateUnreadStyle()

    // 最後に開いた通知を開いた状態にする
    const storedId = Number(sessionStorage.getItem(this.storageKey()))
    if (storedId === this.notificationTargetIdValue) {
      this.contentTarget.classList.add('show')
      this.buttonTarget.classList.remove('collapsed')
      this.buttonTarget.setAttribute('aria-expanded', 'true')
    }

    // 開閉イベントの監視
    this.contentTarget.addEventListener('show.bs.collapse', () => {
      sessionStorage.setItem(this.storageKey(), this.notificationTargetIdValue)
    })

    this.contentTarget.addEventListener('hide.bs.collapse', () => {
      if (
        Number(sessionStorage.getItem(this.storageKey())) ===
        this.notificationTargetIdValue
      ) {
        sessionStorage.removeItem(this.storageKey())
      }
    })
  }

  storageKey() {
    return 'notification_showed_id'
  }

  unreadValueChanged() {
    this.updateUnreadStyle()
  }

  updateUnreadStyle() {
    if (this.unreadValue) {
      this.titleTarget.classList.add('fw-bold')
      this.titleTarget.classList.remove('fw-normal')
      this.unreadBadgeTarget.classList.remove('d-none')
    } else {
      this.titleTarget.classList.remove('fw-bold')
      this.titleTarget.classList.add('fw-normal')
      this.unreadBadgeTarget.classList.add('d-none')
    }
  }

  async toggle() {
    // 開閉状態に関係なく、未読記事のトグルは既読APIを叩く
    if (this.unreadValue) {
      const response = await ApiClient.post(
        `/api/web/notification_targets/${this.notificationTargetIdValue}/read`,
      )
      if (response.status === 200) {
        this.unreadValue = false
        this.unreadValueChanged
      }
    }
  }
}
