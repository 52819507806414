import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['loader']

  start() {
    setTimeout(() => (this.loaderTarget.style.visibility = 'visible'), 500)
  }

  end() {
    this.loaderTarget.style.visibility = 'hidden'
  }

  handleStart(event) {
    if (event.metaKey || event.ctrlKey) return

    this.dispatch('start')
  }
}
