import Tagify from '@yaireo/tagify'
import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    whitelist: Array,
    initialItems: Array,
  }
  static targets = ['tagify', 'tagNames']

  connect() {
    this.tagify = new Tagify(this.tagifyTarget, {
      whitelist: this.itemWhiteList(),
      tagTextProp: 'short_name',
      dropdown: {
        mapValueTo: 'item_name',
        searchKeys: ['item_name', 'short_name', 'hiragana'],
        position: 'all',
        maxItems: Number.POSITIVE_INFINITY,
        enabled: 0,
        placeAbove: false,
      },
      templates: {
        dropdownItemNoMatch() {
          return `<div class='empty'>該当なし</div>`
        },
      },
      enforceWhitelist: true,
      skipInvalid: true,
      originalInputValueFormat: valuesArr =>
        valuesArr.map(item => item.value).join(','),
    })
    this.tagify.loadOriginalValues(this.initialValueList())
  }

  disconnect() {
    this.tagify.removeAllTags()
  }

  initialValueList() {
    return this.initialItemsValue.map(item => this.item_to_tagify_value(item))
  }

  itemWhiteList() {
    return this.whitelistValue.map(item => this.item_to_tagify_value(item))
  }

  item_to_tagify_value(item) {
    return {
      value: item.id,
      item_name: item.item_name,
      short_name: item.short_name,
      hiragana: this.katakanaToHiragana(item.reading),
    }
  }

  katakanaToHiragana(str) {
    return str.replace(/[\u30a1-\u30f6]/g, chr =>
      String.fromCharCode(chr.charCodeAt(0) - 0x60),
    )
  }
}
