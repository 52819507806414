import Rails from '@rails/ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

require('@rails/activestorage').start()
require('channels')
require('bootstrap')

import { Popover, Tab, Tooltip } from 'bootstrap'
import $ from 'jquery'
import './gamelist.js'
import * as Sentry from '@sentry/browser'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'controllers'
// TODO: SNSリンクのURLをコピーで使用しているJSを使用できるようにする

// https://stackoverflow.com/questions/17690202/how-can-i-make-a-form-with-method-get-submit-with-turbolinks
document.addEventListener('turbolinks:load', function () {
  // 環境情報を取得
  const env = document.querySelector('meta[name="env"]')?.content

  // FIXME: form_with かつ local:trueでcsrf_token周りで不具合が起こっていてこの対応が必要
  // Railsのversion up等で解決すれば必要ないので削除する
  // 参考URL: https://stackoverflow.com/questions/63911493/actioncontrollerinvalidauthenticitytoken-error-when-using-form-with
  Rails.refreshCSRFTokens()

  Sentry.init({
    dsn: 'https://e3a7c4edf35368cf1a1cab6dfd0f427d@o526552.ingest.us.sentry.io/4507105108885504',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    sampleRate: 0.1, // sentryの容量を食い潰しそうなので、暫定対応で10%にしておく
    environment: env,
    tracePropagationTargets: [
      /^https:\/\/sugorokuya\.jp\//,
      /^https:\/\/sugorokuya-stage\.jp\//,
    ],
    // tracePropagationTargets: [
    //   /^https:\/\/sugorokuya\.jp\//,
    //   /^https:\/\/sugorokuya-stage\.jp\//,
    //   /^http:\/\/lvh.me/, // ローカル環境でのテスト用
    // ],
    denyUrls: [/\/3\/tsst\/script\/ja\/typesquare\.js/],
  })

  // TypeSquareの読み込みを最適化
  if (typeof Ts !== 'undefined' && typeof Ts.loadFont === 'function') {
    Ts.loadFont()
  } else {
    // TypeSquareが読み込まれていない場合、遅延読み込みを試みる
    const script = document.createElement('script')
    script.src =
      '//typesquare.com/3/tsst/script/ja/typesquare.js?66d418ae626c486cb44d394cac1e02e5&auto_load_font=true'
    script.async = true
    script.onload = () => {
      if (typeof Ts !== 'undefined' && typeof Ts.loadFont === 'function') {
        Ts.loadFont()
      }
    }
    document.head.appendChild(script)
  }

  $('form[data-turboform]').on('submit', function () {
    // console.log(this.action+(this.action.indexOf('?') == -1 ? '?' : '&')+$(this).serialize())
    Turbolinks.visit(
      this.action +
        (this.action.indexOf('?') == -1 ? '?' : '&') +
        $(this).serialize(),
    )
    return false
  })

  // bootstrap navs&tabsでの部分切り替えタブに対し、ID指定で最初からアクティブ指定されているURLを開くときの対応
  const url = window.location.href
  // TODO: stimulusで書き換える
  if (url.indexOf('#') > 0) {
    const activeTab = url.substring(url.indexOf('#') + 1)
    const triggerEl = document.querySelector(
      `.nav[role="tablist"]·a[href="#${activeTab}"]`,
    )
    if (!triggerEl) return
    const tab = new Tab(triggerEl)
    tab.show()
  }

  // bootstrap navs&tabsでの部分切り替えタブをクリックしたときに表示URLも書き換える
  document.querySelectorAll('a[role="tab"]').forEach(tab =>
    tab.addEventListener('click', () => {
      let newUrl
      const hash = tab.getAttribute('href')
      newUrl = url.split('#')[0] + hash
      history.replaceState(null, null, newUrl)
    }),
  )

  // bootstrap tooltipsを利用するための初期化
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  )
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })
  // bootstrap popoverを利用するための初期化
  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]'),
  )
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })
})
